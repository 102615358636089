<template>
  <div class="row">
    <div class="col-12">
      <h2 v-if="!this.$route.params.id">Cadastro de Publicidade</h2>
      <h2 v-if="this.$route.params.id">Atualização de Publicidade</h2>

      <Card>
        <h4>Dados</h4>
        <form @submit.prevent="savePublicity()">
          <div class="form-row">
            <div class="col-md-4">
              <base-input
                v-model="form.name"
                type="text"
                placeholder="Nome"
                label="Nome"
              >
                <HasErrorForm
                  :form="form"
                  field_name="name"
                  slot="helperText"
                />
              </base-input>
            </div>

            <div class="col-md-4">
              <base-input
                v-model="form.order"
                type="text"
                placeholder="Ordem"
                label="Ordem"
              >
                <HasErrorForm
                  :form="form"
                  field_name="order"
                  slot="helperText"
                />
              </base-input>
            </div>

            <div class="col-md-4">
              <base-input
                v-model="form.link"
                type="text"
                placeholder="Link da publicidade"
                label="Link da publicidade"
              >
                <HasErrorForm
                  :form="form"
                  field_name="link"
                  slot="helperText"
                />
              </base-input>
            </div>
          </div>

          <base-checkbox class="mb-3" v-model="show_to_all_cities">
            Exibir para todas as cidades
          </base-checkbox>

          <div class="row">
            <div class="col">
              <div v-if="!show_to_all_cities" class="form-row">
                <div class="col-md-6">
                  <base-dropdown
                    title-classes="btn btn-warning btn-block"
                    titleTag="div"
                    :title="
                      selectedProvince
                        ? `Estado: ${selectedProvince.name}`
                        : 'Estado'
                    "
                  >
                    <a
                      v-for="province in provinces"
                      :key="province.id"
                      class="dropdown-item"
                      href="#"
                      @click="selectProvince(province)"
                      >{{ province.name }}</a
                    >
                  </base-dropdown>
                </div>

                <div class="col-md-6">
                  <base-dropdown
                    title-classes="btn btn-warning btn-block"
                    titleTag="div"
                    :title="
                      selectedCity ? `Cidade: ${selectedCity.name}` : 'Cidade'
                    "
                  >
                    <a v-if="!selectedProvince" class="dropdown-item" href="#"
                      >Selecione um Estado</a
                    >
                    <a
                      v-if="selectedProvince && cities.length <= 0"
                      class="dropdown-item"
                      href="#"
                      >Nenhuma cidade encontrada</a
                    >
                    <span v-if="selectedProvince && cities.length > 0">
                      <a
                        v-for="city in cities"
                        :key="city.id"
                        class="dropdown-item"
                        href="#"
                        @click="selectCity(city)"
                        >{{ city.name }}</a
                      >
                    </span>
                  </base-dropdown>

                  <HasErrorForm
                    :form="form"
                    field_name="city_id"
                    slot="helperText"
                  />
                </div>
              </div>

              <div class="form-row" style="margin: 10px 0;">
                <image-upload
                  select-text="Banner"
                  change-text="Alterar"
                  remove-text="remover"
                  :src="image_preview"
                  :showDeleteOnSrc="image_preview !== null"
                  :showDeleteButton="false"
                  @change="onImageChange"
                >
                </image-upload>
              </div>
              <HasErrorForm
                :form="form"
                field_name="image_url"
                slot="helperText"
              />

              <div class="form-row">
                <div class="col">
                  <div class="form-row">
                    <base-radio
                      name="active"
                      class="col-md-3"
                      v-model="isActive"
                    >
                      Ativo
                    </base-radio>

                    <base-radio
                      name="inactive"
                      class="col-md-3"
                      v-model="isActive"
                    >
                      Inativo
                    </base-radio>
                  </div>
                </div>
                <div class="col">
                  <div class="text-right">
                    <base-button
                      native-type="submit"
                      type="warning"
                      :disabled="loading"
                    >
                      Salvar
                    </base-button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </Card>
    </div>
  </div>
</template>
<script>
import Form from 'vform'
import Card from '@/components/Cards/Card'
import { BaseDropdown, BaseCheckbox, ImageUpload } from '@/components'
import BaseInput from '@/components/Inputs/BaseInput'
import BaseRadio from '@/components/Inputs/BaseRadio'
import HasErrorForm from '@/components/HasErrorForm'

// import objectToFormData from 'object-to-formdata'
import axios from 'axios'

export default {
  name: 'NewEditPublicity',
  data: () => ({
    form: new Form(),
    isActive: 'active',
    show_to_all_cities: false,
    provinces: [],
    cities: [],
    selectedProvince: false,
    selectedCity: false,
    image_preview: null,
    loading: false
  }),

  methods: {
    async onImageChange (file) {
      if (file === null && this.image_preview) {
        this.image_preview = null
        await this.form.delete(`/drivers/${this.driver_id}/image`)
      }

      this.form.image_url = file
    },
    resetLocation () {
      this.selectedCity = false
      this.form.city_id = null
    },
    async selectProvince (province) {
      this.selectedProvince = province
      const {
        data: { cities }
      } = await this.$http.get(`cities/${province.id}`)
      this.cities = cities
    },
    async selectCity (city) {
      this.selectedCity = city
      this.form.city_id = city.id
    },

    async savePublicity () {
      this.loading = true
      try {
        if (this.publicity_id) {
          this.form.id = this.publicity_id
        }

        this.form.is_active = this.isActive === 'active' ? 1 : 0
        this.form.show_to_all_cities = this.show_to_all_cities ? 1 : 0
        // subtitui o plugin formatando manualmente o object-to-formdata
        const dataObj = new FormData()
        Object.keys(this.form).map(key => {
          dataObj.append(key, this.form[key])
        })

        const response = await axios.post('/publicities', dataObj)
        const data = response.data.data

        this.$notify({
          type: 'primary',
          message: this.publicity_id
            ? 'Publicidade atualizada!'
            : 'Publicidade Criada!',
          icon: 'tim-icons icon-bell-55'
        })

        this.publicity_id = data.idu
        this.$router.push({ path: '/publicities' })
      } catch (error) {
        //
      }
      this.loading = false
    }
  },
  async created () {
    const {
      data: { provinces }
    } = await this.$http.get('provinces')
    this.provinces = provinces

    if (this.$route.params.id !== undefined) {
      this.publicity_id = this.$route.params.id

      const {
        data: { data }
      } = await this.$http.get(`/publicities/show/${this.$route.params.id}`)

      this.form = new Form({
        name: data.name,
        order: data.order,
        is_active: !!data.is_active,
        link: data.link
      })

      this.isActive = parseInt(data.is_active) ? 'active' : 'inactive'
      this.show_to_all_cities = !!parseInt(data.show_to_all_cities)

      if (data.image_url) {
        this.image_preview = data.image_url
      }

      if (data.cities[0] !== undefined) {
        const city = data.cities[0]
        await this.selectProvince(
          this.provinces.find(p => p.id === parseInt(city.province_id))
        )
        await this.selectCity(this.cities.find(c => c.id === parseInt(city.id)))
      }
    } else {
      await this.selectProvince(this.provinces.find(p => p.initials === 'PR')) // Paraná
      await this.selectCity(this.cities.find(c => c.ibge_code === '4108304')) // Foz do Iguaçu
    }
  },

  components: {
    Card,
    BaseInput,
    BaseDropdown,
    BaseRadio,
    HasErrorForm,
    ImageUpload,
    BaseCheckbox
  }
}
</script>
